import React, { useEffect, useState, useContext, useRef } from "react";
import { List, Link, Avatar, Tooltip, Form, Input, FloatButton, Button, Select, Checkbox, DatePicker, Steps, Divider, Typography, message, Upload, Card,Row, Col } from 'antd';
import { ExportOutlined, PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined, EyeOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import ProfilAnerkennungProfile from "./ProfilAnerkennungProfile";
import { Tag, Modal } from 'antd';
import moment from 'moment';
import { API, API_BASE } from "../constant";
import MedienCarousel from "../Components/MedienCarousel";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";
import { formConfig } from "../configs/KursAnerkennung";
const { Step } = Steps;
const { Option } = Select;


const StatusEnum = {
  NOT_SUBMITTED: 'Noch nicht eingereicht',
  SUBMITTED_IN_PROGRESS: 'Eingereicht - in Bearbeitung',
  SUBMITTED_REJECTED: 'Eingereicht - abgelehnt',
  SUBMITTED_ACCEPTED: 'Eingereicht - angenommen',
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { RangePicker } = DatePicker;

const fillForm = async (formInput) => {
		// Step 1: Load the PDF form.
        const formUrl = '/pdfs/Anerkennung.pdf';
		const formPdfBytes = await fetch(formUrl).then((res) => {
		    console.debug(res, "!!pdf res!!");
			return res.arrayBuffer()
		});
		const pdfDoc = await PDFDocument.load(formPdfBytes);

		// Step 2: Retrieve the form fields.
		const form = pdfDoc.getForm();

		console.debug(formInput, "!!pdf form!!", form.getFields());
		 form.getTextField('Name').setText('Mario');

		// Step 4: Save the modified PDF.
		const pdfBytes = await pdfDoc.save();

		// Step 5: Create a `Blob` from the PDF bytes,
		const blob = new Blob([pdfBytes], { type: 'application/pdf' });

		// Step 6: Create a download URL for the `Blob`.
		const url = URL.createObjectURL(blob);

		// Step 7: Create a link element and simulate a click event to trigger the download.
		const link = document.createElement('a');
		link.href = url;
		link.download = 'Anerkennungsantrag.pdf';
		link.click();
	};



  const renderFormFields = (user, seminartyp,setSeminartyp, selectedValue, setSelectedValue, secondSelectOptions, setSecondSelectOptions, outerForm,showDozentModal, dozentenData, defaultValues, fileList,handlePreview, handleChange, handleCancel, formsUmbrella, formIndex, setAnbieterNummerIsFilled, setSignedDocHochgeladen) => {
    console.debug("defaultValues, fileList",defaultValues, fileList);


 const handleFirstSelectChange = (value,ind) => {
    var currZV = selectedValue;
    currZV[ind] = value;
    setSelectedValue(currZV);
    // Update second select options based on the selected value
    const options = dozentenData.filter(d => d.someProperty === value);
    const foundDozent = (dozentenData || []).find(d => d.id === value);
    const currV = secondSelectOptions;
    secondSelectOptions[ind] = [...(((foundDozent || {}).dozentinformation || {})["Fortbildungsinhalte Ärztlicher Wundexperte ICW"] || []),
                                                                     ...(((foundDozent || {}).dozentinformation || {})["Fortbildungsinhalte Fachtherapeut Wunde ICW"] || []),
                                                                     ...(((foundDozent || {}).dozentinformation || {})["Fortbildungsinhalte Pflegetherapeut Wunde ICW"] || []),
                                                                     ...(((foundDozent || {}).dozentinformation || {})["Fortbildungsinhalte Wundexperte ICW"] || []), ...(((foundDozent || {}).dozentinformation || {}).AndererEinsatzSeminarthema || "").split(", ")].filter( f => f != "");
    setSecondSelectOptions(JSON.parse(JSON.stringify(secondSelectOptions || [])));
  };

    return (formsUmbrella || []).map((forms, innerIndex) => {
        console.debug("!!forms", forms.forms);

        if (forms.isArray)  {
            return <Form.List name="Seminare" style={{ display: innerIndex === formIndex ? 'block' : 'none'}}>
                     {(fields, { add, remove }) => (
                       <div className="flexHack" style={{ display: innerIndex === formIndex ? 'block' : 'none'}}>
                         {fields.map((field, indexSeminar) => (<Row key={field.key} gutter={[18, 18]}>
                             {(forms.forms || []).map((form, index) => {
                               const fieldName = [field.name, form.key];
                               switch (form.type) {
                                 case 'string':
                                   return (<Col md={16} lg={16} sm={18} xs={18}>
                                     <Form.Item
                                        style={{ display: innerIndex === formIndex  ? 'block' : 'none'}}
                                       key={form.key + index}
                                       name={[field.name, form.key]}
                                       label={form.label}
                                     >
                                       <Input key={form.key + index} />
                                     </Form.Item>
                                     </Col>
                                   );
                                 case 'number':
                                   return (<Col md={16} lg={6} sm={18} xs={18}>
                                     <Form.Item
                                       style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                                       key={index}
                                       name={[field.name, form.key]}
                                       label={form.label}
                                       rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                                     >
                                       <Input type="number" />
                                     </Form.Item>
                                     </Col>
                                   );
                                 case 'date':
                                      return (
                                         <Form.Item
                                        style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                                        key={index}
                                        name={[field.name, form.key]}
                                        label={form.label}
                                        rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                                         >
                                        <RangePicker
                                            locale="de"
                                            showTime={{
                                              format: 'HH:mm',
                                            }}
                                            format="YYYY-MM-DD HH:mm"
                                          />
                                         </Form.Item>
                                      );
                                 case 'select':
                                   return (<>
                                         { outerForm && outerForm.getFieldValue && typeof ((outerForm.getFieldValue("Seminare") || {})[indexSeminar] || {}).Dozent !== "undefined" && <Col md={20} lg={20} sm={20} xs={20}><Button icon={<EyeOutlined />}
                                         type="link"
                                         size="large"
                                         disabled={false}
                                         onClick={() => { showDozentModal((dozentenData || []).find(d => d.id === ((outerForm.getFieldValue("Seminare") || {})[indexSeminar] || {}).Dozent)) }}>
                                                                           Dozent Steckbrief und Qualifikationen anzeigen
                                                                        </Button></Col> }
                                                                        <Col md={16} lg={6} sm={18} xs={18}>
                                     <Form.Item
                                       style={{  display: innerIndex === formIndex ? 'block' : 'none'}}
                                       key={index}
                                       name={[field.name, form.key]}
                                       label={form.label}
                                       rules={[{ required: form.required, message: `${form.label}` }]}
                                     >

                                       <Select placeholder="Bitte auswählen" onChange={(value) => { handleFirstSelectChange(value, indexSeminar) }}>
                                         {(dozentenData || []).filter(d => d.status === StatusEnum.SUBMITTED_ACCEPTED && [...(((d || {}).dozentinformation || {})["Fortbildungsinhalte Ärztlicher Wundexperte ICW"] || []),
                                                                                                                                                               ...(((d || {}).dozentinformation || {})["Fortbildungsinhalte Fachtherapeut Wunde ICW"] || []),
                                                                                                                                                               ...(((d || {}).dozentinformation || {})["Fortbildungsinhalte Pflegetherapeut Wunde ICW"] || []),
                                                                                                                                                               ...(((d || {}).dozentinformation || {})["Fortbildungsinhalte Wundexperte ICW"] || []), ...(((d || {}).dozentinformation || {}).AndererEinsatzSeminarthema || "").split(", ")].filter(f => f !== "").length > 0).map((option, idx) => {

                                         const dozentName = ((((option || {}).dozentinformation || {}).Titel ? ((option || {}).dozentinformation || {}).Titel : '') + ' ' + (((option || {}).dozentinformation || {}).Vorname + " " + option.Name));

                                          return <Option key={idx} value={option.id}>
                                                {dozentName}
                                           </Option>
                                         })}
                                       </Select>


                                     </Form.Item>
                                     </Col></>
                                   );
                                 case 'multiple select':
                                   return (<Col md={16} lg={16} sm={18} xs={18}>
                                     <Form.Item
                                       style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                                       key={form.key + index}
                                       name={[field.name, form.key]}
                                       label={form.label}
                                       rules={[{ required: form.required, message: `${form.label}` }]}
                                     >
                                       <Select mode="multiple" placeholder="Bitte auswählen">
                                                {(secondSelectOptions[indexSeminar] || []).map((option, idx) => {
                                                        return <Select.Option key={idx} value={option}>
                                                                {option}
                                                              </Select.Option>
                                              })}

                                       </Select>
                                     </Form.Item>
                                     </Col>
                                   );
                                 case 'checkbox':
                                   return (<Col md={16} lg={6} sm={18} xs={18}>
                                     <Form.Item

                label={ form.required === true ? <i style={{ marginBottom: -6, paddingTop: 12}}>Ihr Einverständnis ist erforderlich</i> : form.label}
                rules={ form.required === true ? [
                           {
                               required: true,
                                                 transform: value => (value || undefined),  // Those two lines
                                                 type: 'boolean',                           // Do the magic
                               message: 'Ihr Einverständnis ist erforderlich',
                           },
                       ] : undefined }
                                       style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                                       key={index}
                                       name={[field.name, form.key]}
                                       valuePropName="checked"
                                     >
                                       <Checkbox>{form.label}</Checkbox>
                                     </Form.Item>
                                     </Col>
                                   );
                                 case 'upload':
                                   return (<Col md={16} lg={6} sm={18} xs={18}>
                                     <Form.Item
                                       style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                                       key={index}
                                       name={[field.name, form.key]}
                                       label={form.label}
                                     >
                                       <Upload
                                         name="files"
                                         crossOrigin={"use-credentials"}
                                         data={{
                                           ref: "api::kurs.kurs",
                                           field: "anlagen",
                                         }}
                                         fileList={(fileList || []).map((file) => {
                                           if (!file.thumbUrl) {
                                             if ((((file || {}).response || {})[0] || {}).url) {
                                               file.thumbUrl = API_BASE + (((file || {}).response || {})[0] || {}).url;
                                             } else {
                                               file.thumbUrl = API_BASE + ((file || {}).attributes || {}).url;
                                             }
                                           }
                                           return file;
                                         })}
                                         onPreview={handlePreview}
                                         onChange={handleChange}
                                         action={`${API}/upload`}
                                         headers={{ "Authorization": `Bearer ${getToken()}` }}
                                       >
                                         <Button icon={<UploadOutlined />}>Hochladen</Button>
                                       </Upload>
                                     </Form.Item>
                                     </Col>
                                   );
                                 case 'button':
                                   return (<Col md={16} lg={6} sm={18} xs={18}>
                                     <Button
                                       style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                                       onClick={() => fillForm(form)}
                                       icon={<UploadOutlined />}
                                     >
                                       Antrag als PDF zum drucken und unterschreiben herunterladen
                                     </Button>
                                     <hr/>
                                     <p>Oder direkt online unterschreiben:</p>
                                     </Col>
                                   );
                                 case 'htmlbox':
                                   return (<Col md={16} lg={6} sm={18} xs={18}>
                                     <Card
                                       key={index}
                                       bordered={false}
                                       style={{
                                         display: innerIndex === formIndex ? 'block' : 'none',
                                         marginBottom: '16px',
                                         textAlign: 'left',
                                         background: '#f0f2f5',
                                       }}
                                     >
                                       <p style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'left' }}>
                                         {form.htmlbox}
                                       </p>
                                     </Card>
                                     </Col>
                                   );
                                 default:
                                   return null;
                               }
                             })}
                             <Col style={{ textAlign: 'left', marginBottom: '3em' }} span={24}>
                               <Button type="danger" onClick={() => remove(field.name)}>
                                 Seminarpunkt entfernen
                               </Button>
                             </Col>
                           </Row>
                         ))}

                         <Form.Item style={{ width: '100%', paddingTop: '2em' }}>
                           <Button
                             type="dashed"
                             onClick={() => {
                               add();
                             }}
                             icon={<PlusOutlined />}
                           >
                             Seminarpunkt hinzufügen
                           </Button>
                         </Form.Item>
                       </div>
                     )}
                   </Form.List>


        }

        const hasLocation = (((defaultValues || {}).Seminartyp || "").indexOf("Präsenz") > -1 || seminartyp.indexOf("Präsenz") > -1)
        const isDisabled = (user || {}).Rolle === 'ICW-Admin' || (user || {}).Rolle === 'Auditor';
        return (forms.forms || []).map((form, index) => {
          switch (form.type) {
            case 'string':
            const isValid = (((((defaultValues || {}).Seminartyp || "").indexOf("Web-Seminar") > -1 || seminartyp.indexOf("Web-Seminar") > -1) || (form.key !== "Link zum Web-Seminar" && form.key !== "Zugangspasswort für die Zertifizierungsstelle")) && (hasLocation || (form.key != "Veranstaltungsort" && form.key != "Veranstaltungsort2" && form.key != "PLZ" && form.key != "Straße mit Hausnummer" && form.key != "stellvertretung_fachdozent_basisqualifikation")));

              return (
                <Col md={16} lg={16} sm={18} xs={18}><Form.Item
                  style={{ display: innerIndex === formIndex && isValid ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  onChange={(e) => {
                          if (form.label === 'Anbieternummer') {
                            setAnbieterNummerIsFilled(e.target.value != '')
                            console.log('Anbieternummer:', e.target.value)
                          }
                      }
                  }
                  rules={[{ required: isValid && form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <Input />
                </Form.Item></Col>
              );
            case 'number':
              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                >
                  <Input type="number" />
                </Form.Item>
              );

             case 'date':
                  return (
                     <Form.Item
                    style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                    key={index}
                    name={form.key}
                    label={form.label}
                    rules={[{ required: form.required, message: `Bitte ausfüllen:  ${form.label}!` }]}
                     >
                    <RangePicker
                        locale="de"
                        showTime={{
                          format: 'HH:mm',
                        }}
                        format="YYYY-MM-DD HH:mm"
                      />
                     </Form.Item>
                  );
            case 'select':
                const isValidd = (((((defaultValues || {}).Seminartyp || "").indexOf("Web-Seminar") > -1 || seminartyp.indexOf("Web-Seminar") > -1) || (form.key !== "Link zum Web-Seminar" && form.key !== "Zugangspasswort für die Zertifizierungsstelle")) && (hasLocation || (form.key != "Veranstaltungsort" && form.key != "PLZ" && form.key != "Straße mit Hausnummer" && form.key != "stellvertretung_fachdozent_basisqualifikation")));

              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex && isValidd ? 'block' : 'none'}}

                                    key={form.key + index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: isValidd && form.required, message: `${form.label}` }]}
                >
                  <Select onSelect={(v) => {
                                          if (form.key === 'Seminartyp') {
                                              setSeminartyp(v);
                                          }
                                      }} placeholder="Bitte auswählen">
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'multiple select':
              return (
                <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index}
                  name={form.key}
                  label={form.label}
                  rules={[{ required: form.required, message: `${form.label}` }]}
                >
                  <Select mode="multiple" placeholder="Bitte auswählen">
                    {form.options.sort((a, b) => a.localeCompare(b)).map((option, idx) => (
                      <Option key={idx} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            case 'checkbox':
              return (
                <Form.Item

                label={ form.required === true ? <i style={{ marginBottom: -6, paddingTop: 12}}>Ihr Einverständnis ist erforderlich</i> : form.label}
                rules={ form.required === true ? [
                           {
                               required: true,
                                                 transform: value => (value || undefined),  // Those two lines
                                                 type: 'boolean',                           // Do the magic
                               message: 'Ihr Einverständnis ist erforderlich',
                           },
                       ] : undefined }
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                   key={index} name={form.key} valuePropName="checked">
                  <Checkbox>{form.label}</Checkbox>
                </Form.Item>
              );
            case 'upload':
              console.debug("!!fileList", fileList);
              return (
                isDisabled && (fileList || []).length > 0 ? <div style={{ display: innerIndex === formIndex ? 'block' : 'none'}}><MedienCarousel overwriteTitle={"Anlagen"} pickedData={(fileList || [])} /></div> : <Form.Item
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                  key={index} name={form.key} label={form.label}>

                  <Upload name="files"
                          crossOrigin={"use-credentials"}
                          data={{
                            ref: "api::kurs.kurs",
                            field: "anlagen",
                          }}
                            fileList={(fileList || []).map((file) => {
                                  console.debug("!!file", file);
                                  if (!file.thumbUrl) {
                                      if ((((file || {}).response || {})[0] || {}).url) {
                                       file.thumbUrl = API_BASE +  (((file || {}).response || {})[0] || {}).url;
                                      } else {
                                        file.thumbUrl = API_BASE +  ((file || {}).attributes || {}).url;
                                      }
                                  }

                            return file;
                      })}
                      onPreview={handlePreview}
                      onChange={handleChange}

                          action={`${API}/upload`}
                          headers={{ "Authorization": `Bearer ${getToken()}`}}>
                    <Button icon={<UploadOutlined />}>Hochladen</Button>
                  </Upload>
                </Form.Item>
              );
              case 'button':
              return (
                    <Button
                  style={{ display: innerIndex === formIndex ? 'block' : 'none'}}
                   onClick={() => fillForm(form)} icon={<UploadOutlined />}>Antrag als PDF zum drucken und unterschreiben herunterladen</Button>
                    )
            case 'htmlbox':
              return (
                <Card
                   key={index} bordered={false} style={{ display: innerIndex === formIndex ? 'block' : 'none', marginBottom: '16px', textAlign: 'left', background: '#f0f2f5' }}>
                      <p style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'left' }}>
                        {form.htmlbox}
                      </p>
                    </Card>
              );
            default:
              return null;
          }
        })});
  };


const FormComponent = ( {veranstaltungenData, auditData, anbieterData, history, type, id, dozentenData, herstellerData} ) => {

    const [loading, setLoading] = useState(false);
    const { user, isLoading, setUser } = useAuthContext();
    const [refId, setRefId] = useState('');
    const [current, setCurrent] = useState(0);
    const [anbieterNummerIsFilled, setAnbieterNummerIsFilled] = useState(false);
    const [signedDocHochgeladen, setSignedDocHochgeladen] = useState(false);
    const [form] = Form.useForm();
    const [defaultValues, setDefaultValues] = useState({ Seminare: [""]});
    const [referenten, setReferenten] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isKommentarModalVisible, setIsKommentarModalVisible] = useState(false);
    const [currentDozent, setCurrentDozent] = useState(null)
      const [errorFields, setErrorFields] = useState([]);

    const showDozentModal = (dozent) => {
        setCurrentDozent(dozent);
        setIsKommentarModalVisible(true);
    };
    const [selectedValue, setSelectedValue] = useState([]);
    const [secondSelectOptions, setSecondSelectOptions] = useState([]);
    const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id) || {};
    const [fileList,  setFileList] = useState(((veranstaltung || {}).anlagen || {}).data || []);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [seminartyp, setSeminartyp] = useState('');
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
       console.debug("file",file);
      if (!file.thumbUrl && !file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.thumbUrl || file.url || file.preview || ((((file || {}).attributes || {}).formats || {}).thumbnail || {}).url);
      setPreviewOpen(true);
      setPreviewTitle((((file || {}).attributes) || {}).name || file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || (Array.isArray(value) && value.length === 1 && value[0] === '') || value === null || value === "");
        const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

        if (Object.keys(defaultValues || {}).length > 1) {
            Object.keys(defaultValues).forEach((key) => {
                if (key === "Veranstaltungsdatum" && defaultValues[key]) {
                    console.debug("defaultValues[key]1",defaultValues[key]);
                    if (typeof defaultValues[key] === 'string') {
                        defaultValues[key] = undefined;
                    console.debug("defaultValues[key]2",defaultValues[key]);
                    } else {
                        defaultValues[key] = defaultValues[key].map(d => moment(d, dateFormat));
                    console.debug("defaultValues[key]3",defaultValues[key]);
                    }
                }
            });
        }

        if (isFormEmpty) {
            form.setFieldsValue(defaultValues)
            if (defaultValues.anlagen) {
                setFileList(defaultValues.anlagen);
            }
        }
    }, [form, defaultValues])

    useEffect(() => {
        const veranstaltung = herstellerData.find((veranstaltung) => veranstaltung.id == id) || {};

        const defaultValues = {};

        if (Object.keys(veranstaltung.kursinformation || {}).length > 0) {
            Object.keys(veranstaltung.kursinformation).forEach((key) => {
                defaultValues[key] = veranstaltung.kursinformation[key];
            });
        }

        Object.keys(veranstaltung).forEach((key) => {
            defaultValues[key] = veranstaltung[key];
        })

        setDefaultValues({
            ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
         });

    }, [herstellerData]);


  const saveFortschritt = async (status) => {
    setLoading(true);
    try {
        const data = form.getFieldsValue();
        const referent = herstellerData.find((referent) => referent.id == id);
        let response;
        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

         if (!dataToPost["Bild"] || (referent && (dataToPost["Bild"] || {}).data)) delete dataToPost["Bild"];
                 dataToPost.anlagen = (fileList || []).map( media => {
                             if (((media.response) || []) [0]) {
                                 return media.response[0];
                             } else {
                                 return media;
                             }
                          });

         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Bild"]  = dataToPost.dragger[0].response[0];


         dataToPost.createdby = user.id;

        if (referent && status) {
            dataToPost.status = status;
         } else {
            dataToPost.status = "Noch nicht eingereicht";
         }

         delete dataToPost.dragger;
        //         delete dataToPost.Vergabestelle;
        //          if (Object.keys(dataToPost.Vergabestelle).length === 0 || typeof ((dataToPost || {}).Vergabestelle || {}).Punkte == "undefined") delete dataToPost.Vergabestelle;

        dataToPost.kursinformation = data;

      let responseData = {};
            if (referent) {
              response = await fetch(`${API}/kurss/${referent.id}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                  body: JSON.stringify({data: dataToPost}),
                });
              responseData = await response.json();
              message.success("Daten erfolgreich gespeichert!");
              if (status) history.push('/admin/aktionen/Seminare');
            } else {
              response = await fetch(`${API}/kurss`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                  body: JSON.stringify({data: dataToPost}),
                });
              responseData = await response.json();
              if (status) {
                  dataToPost.status = status;
                  await fetch(`${API}/kurss/${responseData.data.id}`, {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${getToken()}`,
                    },
                    body: JSON.stringify({data: dataToPost}),
                  });

                history.push('/admin/aktionen/Seminare');
              } else {
                message.success("Daten erfolgreich gespeichert!");
                history.push(`/admin/aktionen/Seminar/${responseData.data.id}`);
              }
            }
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  const onNext = () => {
   // form.validateFields().then(() => {
      setCurrent(current + 1);
   // }).catch(errorInfo => {
      //message.error('Bitte füllen Sie alle Pflichtfelder aus.');
   // });
  };

  const onPrev = () => {
    setCurrent(current - 1);
  };

   const onChange = (value: number) => {
      console.log('onChange:', value);
      setCurrent(value);
    };

  const onFinish = (values) => {
    console.log('Form values:', values);
    message.success('Formular erfolgreich übermittelt!');
  };



  const referentendata = (referenten || []).filter(r => r.attributes ).map((d, i) =>  ({
            id : d.id,
            title: `${d.attributes.Vorname} ${d.attributes.Name}`,
            avatar: d.attributes.Bild ? API_BASE + d.attributes.Bild.data.attributes.url : "",
            description:
              d.attributes.Bio,
            content: <>
                <h4>Qualifikationen</h4>
                <p>
                    {([...(d.attributes.Zusatzqualifikation || []), ...(d.attributes.Grundqualifikation || [])]).map(theme => (
                      <Tag key={theme}>{theme}</Tag>
                    ))}
                </p>
            </>,
          }));

    const anbieterDataRelatedId = ((anbieterData || []).find(r => r.createdby === defaultValues.createdby) || {}).id;
    const isIcw = (user || {}).Rolle === 'ICW-Admin';
    const isAuditor = (user || {}).Rolle === 'Auditor';



  return (<>
     { (isIcw || isAuditor) && typeof anbieterDataRelatedId !== "undefined" && <Row
      style={{
      background: '#eb008c12',
          height: '672px',
          overflow: 'auto'
          }}
      gutter={[18, 18]}>
            <Col md={28} style={{ textAlign: 'left' }}>

         <Divider orientation="left">Anbieterprofil  ---  Für den Seminarantrag bitte nach unten scrollen!</Divider>
            {/*<Button type="primary" size={'large'} icon={<ExportOutlined />}
                              onClick={() => window.open(`/admin/aktionen/Profil/${anbieterDataRelatedId}`, '_blank')}
                             >Anbieterprofil in neuem Tab öffnen</Button>*/}
                             <ProfilAnerkennungProfile history={history} type={"anbieter-profil"} id={anbieterDataRelatedId} herstellerData={veranstaltungenData.filter(v => v.type === "anbieter-profil")}  referentenData={veranstaltungenData.filter(v => v.type === "Referent")} veranstaltungenData={veranstaltungenData.filter(v => v.type === "Veranstaltung")} />

         <Divider /></Col>
     </Row>}
     { (isIcw || isAuditor) && typeof anbieterDataRelatedId !== "undefined" && <Row gutter={24}><Col md={24} style={{ textAlign: 'left' }}><Divider orientation="left">Seminarantrag</Divider></Col></Row>}


     <Row gutter={[18, 18]}>
     <Col md={3}>
        <Steps direction="vertical" onChange={onChange} current={current} style={{ marginBottom: '20px' }}>
          {formConfig.map((item, index) => (
            <Step
      key={index}
      title={
        <Tooltip arrow={false} placement="right" title={item.title || ""}>
          <span>{(item.title || "").substr(0, (item.title || "").indexOf(" "))}</span>
        </Tooltip>
      }
      icon={<Tooltip arrow={false} placement="right" title={item.title || ""}>
                      {item.icon}
                    </Tooltip>}
    />
          ))}
        </Steps>
        </Col>
        <Col md={21}>
            <Form
            disabled={(user || {}).Rolle === 'ICW-Admin' || (user || {}).Rolle === 'Auditor' }
            initialValues={defaultValues} form={form} layout="vertical" onFinish={onFinish}>
              <Card
                    title={formConfig[current].title}
                  actions={[
                      (((defaultValues || {}).status || "").indexOf("Eingereicht -") === -1 && (user || {}).Rolle !== 'ICW-Admin' && (user || {}).Rolle !== 'Auditor') && <Button onClick={() => saveFortschritt()} style={{ margin: '0 8px' }}
                          type="primary"
                          icon={<SaveOutlined />}>
                        Fortschritt speichern
                      </Button>,
                      <Button disabled={!(current > 0)} style={{ margin: '0 8px' }} onClick={onPrev}>
                        Zurück
                      </Button>,
                      <Button disabled={!(current < formConfig.length - 1)} type="primary" onClick={onNext}>
                        Weiter
                      </Button>,
                        (user || {}).Rolle === 'Auditor' && <Button disabled={false} icon={<FormOutlined />}
                                                                            onClick={() => {
                                                                                const auditBogen = (auditData.find((audit) => audit.Kursnummer === defaultValues.Kursnummer));
                                                                                const auditId = (auditBogen || {}).id || "";
                                                                                history.push(`/admin/aktionen/Auditbericht/${auditId}`, { kursnummer: defaultValues.Kursnummer, auditTyp: isAuditor ? (((user || {}).auditinfo || {})[defaultValues.Kursnummer] || {})["auditTyp"] : "", auditArt: isAuditor ? (((user || {}).auditinfo || {})[defaultValues.Kursnummer] || {})["auditArt"] : "" } )
                                                                            }  }
                                                                           >{ typeof (auditData.find((audit) => audit.Kursnummer === defaultValues.Kursnummer) || {}).id === "undefined" ? "Bericht verfassen..." : "Bericht aktualisieren..."}</Button>,

                         (user || {}).Rolle === 'ICW-Admin' && <Button icon={<CheckCircleOutlined />} danger disabled={false} type="primary" onClick={() => {


                              history.push('/admin/aktionen/Seminarverwaltung', {open: id});
                        }}>
                          Ablehnen
                        </Button>,
                         (user || {}).Rolle === 'ICW-Admin' && <Button style={{ color: 'white', background: '#52c41a' }} icon={<CheckCircleOutlined />} disabled={false} type="primary" onClick={() => {
                              console.debug("go to ");

                              history.push('/admin/aktionen/Seminarverwaltung', {openApprove: id});
                        }}>
                          Akzeptieren
                        </Button>,

                      (user || {}).Rolle !== 'ICW-Admin' && (user || {}).Rolle !== 'Auditor' && <Button onClick={() => {

                                                      form.validateFields().then(() => {
                                                            saveFortschritt("Eingereicht - in Bearbeitung")
                                                      }).catch(errorInfo => {

                                                      setErrorFields(errorInfo.errorFields);

                                                      message.open({
                                                            type: 'error',
                                                            content: 'Antrag noch nicht komplett. Bitte füllen Sie alle Pflichtfelder aus: ' + errorInfo.errorFields.map(e => e.errors).join(', '),
                                                            className: 'custom-class',
                                                            style: {
                                                              padding: '3rem',
                                                              marginTop: '20vh',
                                                              fontSize: '20px',
                                                              backgroundColor: '#eb008c',
                                                              fontWeight: 800
                                                            },
                                                          });
                                                      });

                       }} disabled={false} type="primary" htmlType="submit">

                        Einreichen
                      </Button>
                    ]}
                    style={{ margin: '0 auto' }}>
                    <div style={{ height: 414, overflow: 'auto' }}>
                    { formConfig[current].htmlbox && <Card bordered={false} style={{ paddingBottom: '11px', marginBottom: '16px', textAlign: 'right', background: '#f0f2f5' }}>
                          <Typography.Title level={4} style={{ color: '#1890ff' }}>{form.label}</Typography.Title>
                          <Typography.Paragraph style={{ marginLeft: '16px', marginRight: '16px' }}>
                            {formConfig[current].htmlbox}
                          </Typography.Paragraph>
                        </Card> }

                      { renderFormFields(user, seminartyp, setSeminartyp, selectedValue, setSelectedValue, secondSelectOptions, setSecondSelectOptions, form, showDozentModal, dozentenData, defaultValues, fileList, handlePreview, handleChange, handleCancel, formConfig, current, setAnbieterNummerIsFilled, setSignedDocHochgeladen) }
                      <Divider />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      </div>
                    </div>
      </Card>
    </Form>
            </Col>

        </Row>

        <Modal
                title={'Dozenten Steckbrief'}
                visible={isKommentarModalVisible}
                onCancel={() => setIsKommentarModalVisible(false)}
                footer={[
                  <Button key="close" onClick={() => setIsKommentarModalVisible(false)}>
                    Schließen
                  </Button>,
                ]}
              >
                <div>

                <Divider orientation="left">
                {((((currentDozent || {}).dozentinformation || {}).Titel ? ((currentDozent || {}).dozentinformation || {}).Titel : '') + ' ' + (((currentDozent || {}).dozentinformation || {}).Vorname + " " + (currentDozent || {}).Name))}
                </Divider>
                    <Row gutter={[24, 24]}>
                              {((currentDozent || {}).dozentinformation || {}).Kategorie && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Kategorie:</strong> {((currentDozent || {}).dozentinformation || {}).Kategorie}</Typography.Text>
                                </Col>
                              )}
                              {((currentDozent || {}).dozentinformation || {}).Stadt && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Stadt:</strong> {((currentDozent || {}).dozentinformation || {}).Stadt}</Typography.Text>
                                </Col>
                              )}
                              {((currentDozent || {}).dozentinformation || {}).Basisqualifikation && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Basisqualifikation:</strong></Typography.Text><br/>
                                  {(((currentDozent || {}).dozentinformation || {}).Basisqualifikation || []).map(theme => (
                                                  <Tag key={theme}>{theme}</Tag>
                                                ))}
                                </Col>
                              )}
                              {((currentDozent || {}).dozentinformation || {}).Einsatz_bei_Seminar && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Einsatz bei Seminar:</strong></Typography.Text><br/>
                                  {(((currentDozent || {}).dozentinformation || {}).Einsatz_bei_Seminar || []).map(theme => (
                                                  <Tag key={theme}>{theme}</Tag>
                                                ))}
                                </Col>
                              )}
                              {((currentDozent || {}).dozentinformation || {}).Zusatzqualifikation && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Zusatzqualifikation:</strong></Typography.Text><br/>
                                  {(((currentDozent || {}).dozentinformation || {}).Zusatzqualifikation || []).map(theme => (
                                                  <Tag key={theme}>{theme}</Tag>
                                                ))}

                                    {[...(((currentDozent || {}).dozentinformation || {}).Zusatzqualifikation || []), ...(((currentDozent || {}).dozentinformation || {}).AndereQualifikation || "").split(", ")].map(theme => (
                                      <Tag key={theme}>{theme}</Tag>
                                    ))}
                                </Col>
                              )}

                              {(
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Einsatz im Seminarthema:</strong></Typography.Text><br/>
                                  {
                                  [...(((currentDozent || {}).dozentinformation || {})["Fortbildungsinhalte Ärztlicher Wundexperte ICW"] || []),
                                      ...(((currentDozent || {}).dozentinformation || {})["Fortbildungsinhalte Fachtherapeut Wunde ICW"] || []),
                                      ...(((currentDozent || {}).dozentinformation || {})["Fortbildungsinhalte Pflegetherapeut Wunde ICW"] || []),
                                      ...(((currentDozent || {}).dozentinformation || {})["Fortbildungsinhalte Wundexperte ICW"] || []), ...(((currentDozent || {}).dozentinformation || {}).AndererEinsatzSeminarthema || "").split(", ")].filter( f => f != "").map(theme => (
                                                  <Tag key={theme}>{theme}</Tag>
                                                ))}
                                </Col>
                              )}

                              {((currentDozent || {}).dozentinformation || {}).Derzeitige_Tätigkeit && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Berufspraxis im Themengebiet:</strong> {((currentDozent || {}).dozentinformation || {}).Derzeitige_Tätigkeit}</Typography.Text>
                                </Col>
                              )}
                              {((currentDozent || {}).dozentinformation || {}).Derzeitiger_Arbeitgeber && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Derzeitiger Arbeitgeber:</strong> {((currentDozent || {}).dozentinformation || {}).Derzeitiger_Arbeitgeber}</Typography.Text>
                                </Col>
                              )}
                              {((currentDozent || {}).dozentinformation || {}).Berufspraxis_im_Themengebiet && (
                                <Col xs={24} sm={24}>
                                  <Typography.Text><strong>Berufspraxis im Themengebiet:</strong> {((currentDozent || {}).dozentinformation || {}).Berufspraxis_im_Themengebiet}</Typography.Text>
                                </Col>
                              )}
                              { ((currentDozent || {}).anlagen || []).length > 0 && <Col xs={24} sm={24}>
                                <MedienCarousel overwriteTitle={"Anlagen"} pickedData={currentDozent.anlagen} />
                              </Col> }
                                      </Row>

                </div>
          </Modal>

          </>
  );
};

export default FormComponent;

import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Input, Button, Select, Checkbox, DatePicker, Steps, Divider, Typography, message, Upload, Card,Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined } from '@ant-design/icons';

export const Rezertifizierungsausdit = [
                              {
                                "title": "1. Allgemeine Anforderungen an den Veranstalter (Prüffeld 1)",
                                "icon": <FormOutlined />,
                                "forms": [
                                  {
                                    "label": "Auditteilnehmer des Anbieters",
                                    "type": "string",
                                    "key": "Auditteilnehmer des Anbieters"
                                  },
                                  {
                                    "label": "Auditor/en",
                                    "type": "string",
                                    "key": "Auditor/en"
                                  },
                                  {
                                    "label": "Auditart",
                                    "type": "select",
                                    "key": "Auditart",
                                    "options": ["Regelaudit", "Überwachungsaudit", "Berücksichtigung von Auflagen vorangegangener Audits"]
                                  },
                                  {
                                    "label": "Audittyp",
                                    "type": "select",
                                    "key": "Audittyp",
                                    "options": ["Aktenaudit", "Prüfungsaudit", "Seminaraudit"]
                                  },
                                  {
                                      "label": "1.1 Die Ausschreibung der Bildungsmaßnahme entspricht den Kriterien der ICW/TÜV.",
                                      "type": "select",
                                      "key": "1.1",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 1.1"
                                    },
                                    {
                                      "label": "1.2 Der Antragsteller ist bei Kooperationen der Veranstaltung „vor Ort“ oder hat einen Vertreter in die Aufgaben eingewiesen? (dieser ist nicht ein Mitarbeiter einer kooperierenden Industrie)",
                                      "type": "select",
                                      "key": "1.2",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 1.2"
                                    },
                                    {
                                      "label": "1.3 Die Bildungsmaßnahme findet am beantragten Ort/bzw. beantragter Webseite und zur gemeldeten Zeit statt.",
                                      "type": "select",
                                      "key": "1.3",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 1.3"
                                    },
                                    {
                                      "label": "1.4 Die Anwesenheit der Teilnehmer ist der Seminarform entsprechend und wird per Unterschriftenliste dokumentiert. Stellvertretend macht der Seminarleiter Screenshots oder fragt TN ab und dokumentiert diese (Web-Seminar). Bei Web-Seminaren ist die Kamera pro Teilnehmer aktiviert.",
                                      "type": "select",
                                      "key": "1.4",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 1.4"
                                    },
                                    {
                                      "label": "1.5 Die Teilnehmer erhalten eine Teilnahmebescheinigung nach den Vorgaben der ICW/TÜV.",
                                      "type": "select",
                                      "key": "1.5",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                        "key": "Anmerkungen 1.5"
                                    },
                                    {
                                      "label": "1.6 Es ist sichergestellt, dass die Teilnehmer eine dem zeitlichen Umfang ihrer Anwesenheit entsprechende Teilnahmebescheinigung erhalten.",
                                      "type": "select",
                                      "key": "1.6",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                        "key": "Anmerkungen 1.6"
                                    }

                                ]
                              },
                              {
                                "title": "2. Organisatorische Umsetzung des Lehrgangskonzeptes (Prüffeld 2)",
                                "icon": <FormOutlined />,
                                "forms": [
                                    {
                                      "label": "2.1 Der Unterricht entspricht den eingereichten Themen und ggf. digitalen Methoden (Web-Seminar) für diese Rezertifizierung.",
                                      "type": "select",
                                      "key": "2.1",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.1"
                                    },
                                    {
                                      "label": "2.2 Die geplanten Unterrichtsstunden werden eingehalten.",
                                      "type": "select",
                                      "key": "2.2",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.2"
                                    },
                                    {
                                      "label": "2.3 Die vorgegebenen Zeiten werden eingehalten, pünktlicher Beginn, Pausen.",
                                      "type": "select",
                                      "key": "2.3",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.3"
                                    },
                                    {
                                      "label": "2.4 Der Unterricht wird durch die bestätigten Dozenten in Echtzeit wie beantragt erbracht.",
                                      "type": "select",
                                      "key": "2.4",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.4"
                                    },
                                    {
                                      "label": "2.5 Die Räumlichkeiten bzw. die Meetingplattform und die Teilnehmerzahl sind dem Unterrichtskonzept entsprechend.",
                                      "type": "select",
                                      "key": "2.5",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.5"
                                    },
                                    {
                                      "label": "2.6 Es findet eine Evaluation der Veranstaltung durch die Teilnehmer statt.",
                                      "type": "select",
                                      "key": "2.6",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.6"
                                    },
                                    {
                                      "label": "2.7 Die Veranstaltung wurde wie gemeldet ohne Produktausstellung oder sonstiges Werbematerial umgesetzt.",
                                      "type": "select",
                                      "key": "2.7",
                                      "options": ["voll", "teilweise", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 2.7"
                                    }
                                ]
                              },
                              {
                                "title": "3. Qualität des Unterrichtes (Prüffeld 3)",
                                "icon": <FormOutlined />,
                                "forms": [
                                  {
                                    "label": "3.1 Der vermittelte Stoff schließt mindestens an das Niveau des Wundexperten ICW e. V. (d. h. daran anknüpfend, aufbauend, ergänzend …)",
                                    "type": "select",
                                    "key": "3.1",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.1"
                                  },
                                  {
                                    "label": "3.2 Die methodisch didaktische Gestaltung des Unterrichts/der Vorträge ist dem Gegenstand der Zielgruppe und der Seminarform angemessen.",
                                    "type": "select",
                                    "key": "3.2",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.2"
                                  },
                                  {
                                    "label": "3.3 Der Unterricht erfolgt produktneutral in Bezug auf Präsentation, mündlichen Erläuterungen und Anschauungsmaterial.",
                                    "type": "select",
                                    "key": "3.3",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.3"
                                  },
                                  {
                                    "label": "3.4 Es wird ein relevanter Praxisbezug in der Lehrveranstaltung hergestellt.",
                                    "type": "select",
                                    "key": "3.4",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.4"
                                  },
                                  {
                                    "label": "3.5 Sofern die Teilnehmer Unterlagen zum Seminar erhalten: Sind diese dem aktuellen Stand der Fachkenntnisse entsprechend und berücksichtigen z.B. aktuelle Expertenstandards, Leitlinien und Vorgaben der ICW.",
                                    "type": "select",
                                    "key": "3.5",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.5"
                                  },
                                  {
                                    "label": "3.6 Die Präsentationen entsprechen den formalen Vorgaben z.B. Quellenangaben bei Definitionen und Bildern.",
                                    "type": "select",
                                    "key": "3.6",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.6"
                                  },
                                  {
                                    "label": "3.7 Die Präsentationen und Ausführung entsprechen dem aktuellen Stand der Fachkenntnis.",
                                    "type": "select",
                                    "key": "3.7",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.7"
                                  },
                                  {
                                    "label": "3.8 Teilnehmer können sich aktiv in das Seminargeschehen einbringen. Bei Web-Seminar über Chat, Mikrophon und nonverbal. Auf Fragen der Teilnehmer wird adäquat eingegangen.",
                                    "type": "select",
                                    "key": "3.8",
                                    "options": ["voll", "teilweise", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 3.8"
                                  }
                                ]
                              },
                                        {
                                           printHide: true,
                                            title: '4. Bestätigung und Einreichen',
                                            icon: <TrophyOutlined />,
                                            htmlbox: `Um den Antrag fertigzustellen, drucken Sie bitte dieses Formular aus und laden es unterschrieben wieder hoch. Danach drücken Sie bitte auf "Einreichen" um den Antrag abzuschicken.`,
                                              forms: [
                                              { label: 'herunterladen', type: 'button' },
                                            { label: 'Unterschriebener Antrag:', type: 'upload' },
                                            { required: true, key: 'bestaetigung' , label: 'Die Auditergebnisse wurden mit dem Verantwortlichen des Bildungsanbieters besprochen und zur Kenntnis genommen. Notwendige Schlussfolgerungen und Korrekturmaßnahmen werden durch die Anerkennungs- und Zertifizierungsstelle von TÜV und ICW e. V. festgelegt und dem Anbieter in Schriftform zur Kenntnis gebracht.', type: 'checkbox' }],
                                          },// 2 unterschriften // Ort Datum
                            ];

import React, { useState, Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import Autocomplete from '../Autocomplete';
import Background from './Background';
import Logo from '../Images/ICW_Logo_mobile.png';
import '../Styles/Header.css';
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";
import { Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown,Divider, message, Space, Tooltip } from 'antd';
import { elastic as Menu } from 'react-burger-menu'




function scrollToTargetAdjusted(){
    var element = document.getElementById('go-filter');
    var headerOffset = document.getElementsByClassName("first-line")[0].clientHeight + document.getElementsByClassName("second-line")[0].clientHeight + 14;
//    ("first-line").clientHeight + document.getElementById("second-line").clientHeight;

    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
}

class Header extends Component {
  constructor() {
    super()
    this.state = {
        selectedOption: { label: "Veranstaltung", value: "Veranstaltung", route: "/veranstaltungen" },
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        experience: 'Suchen',
        searchText: ''
      };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  changeSearchText = event => this.setState({ searchText: event.target.value });

  changeExperience = experience => this.setState({ experience: experience });

  handleChange = selectedOption => {
      this.setState({ selectedOption: selectedOption });
  //    this.changeUrl(selectedOption.value);
    };

  render() {
    const {
      backgroundImagesData,
      navigationData,
      selectedCity,
      veranstaltungenData,
      history,
      setFilterAdvancedHighlighted,
      filterAdvancedHighlighted
    } = this.props;


  const atHome = false; //(this.props.history.location.pathname === "/");
  const atVeranstaltungen = (this.props.history.location.pathname === "/veranstaltungen");
  const atVersorgungspartner = (this.props.history.location.pathname === "/versorgungspartner");
  const atReferenten = (this.props.history.location.pathname === "/referenten");
  const atHersteller = (this.props.history.location.pathname === "/hersteller");


    const { selectedOption } = this.state;

    const isLeaf = history.location.pathname.match(/\/veranstaltungen\/(\d+)/) || history.location.pathname.match(/\/referenten\/(\d+)/) || history.location.pathname.match(/\/produkt\/(\d+)/) || history.location.pathname.match(/\/hersteller\/(\d+)/);

    return (
      <React.Fragment>
        <HeaderNav
          veranstaltungenData={veranstaltungenData}
          user={this.props.user}
          handleChange={this.handleChange}
          changeSearchText={this.changeSearchText}
          experience={this.state.experience}
          changeExperience={this.changeExperience}
          searchText={this.state.searchText}
          filterAdvancedHighlighted={this.state.filterAdvancedHighlighted}
          history={history}
          pathname={history.location.pathname}
          selectedCity={selectedCity}
          navigationData={navigationData}
        />
        <Background veranstaltungenData={veranstaltungenData} history={this.props.history} backgroundImagesData={backgroundImagesData} />

      </React.Fragment>
    );
  }
}


const SioAvatar = (props) => {
    const { user } = useAuthContext();
    return (
            <Tooltip placement="bottom" title={ user ? 'Benutzerkonto' : 'Anmelden'}>
                <Avatar style={{ marginTop: -8, backgroundColor: (user ? '#52c41a' : '#f8f8f8'), color: (user ? '#fff' : '#eb008c') }} icon={<UserOutlined />} />
            </Tooltip>
    );
}

/*
    return (<>
    user ? (<Dropdown menu={{
  onClick: props.handleMenuClick,
  items: [
    {
        label: 'Benutzerkonto',
        key: '1',
      },
    {
        label: 'Abmelden',
        key: '2',
        danger: true,
      }
    ]}}>
          <Button>
            <Space>
              <Avatar style={{ marginTop: -8, backgroundColor: (user ? '#52c41a' : '#f8f8f8'), color: (user ? '#fff' : '#eb008c') }} icon={<UserOutlined />} />
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>) : (<Tooltip placement="bottom" title={ user ? 'Benutzerkonto' : 'Anmelden'}>
            <Avatar style={{ marginTop: -8, backgroundColor: (user ? '#52c41a' : '#f8f8f8'), color: (user ? '#fff' : '#eb008c') }} icon={<UserOutlined />} />
        </Tooltip>)
        </>);
        */
export class HeaderNav extends Component {
  constructor() {
    super()
    console.debug("this",this);
    this.state = {
      isOpen: false,
      adminActive: false
    }
  }

  componentDidMount() {
    // Access history after the component has mounted
    const { pathname } = this.props;
      this.setState({
        adminActive: !!this.props.user //pathname.indexOf("/admin") > -1
      });
  }

  componentDidUpdate(prevProps) {
      const { pathname } = this.props;
      console.debug("xxxxx!!!!!!!", this.props);


      if (prevProps.pathname !== pathname || prevProps.user !== this.props.user) {
        this.setState({
          adminActive: !!this.props.user //pathname.indexOf("/admin") > -1
        });
      }
  }



  static defaultProps = {
    navigationData: [
    ],
    icwMapping: {
    'Aktionen': 'Aktionen',
    'Mein Profil': 'Anbieterverwaltung',
    'Meine Dozenten': 'Seminarverwaltung',
    'Meine Seminare': 'Auditorverwaltung',
    'Dozentenverwaltung': 'Dozentenverwaltung',
    },
    icwAuditor: {
    'Aktionen': 'Aktionen',
    'Seminarverwaltung': 'Seminarverwaltung',
    'export-kurse': 'Export: Kurse',
    },
    icwUrlMapping: {
    'admin/aktionen': 'admin/aktionen',
    'admin/aktionen/Profil': 'admin/aktionen/Anbieterverwaltung',
    'admin/aktionen/Dozenten': 'admin/aktionen/Seminarverwaltung',
    'admin/aktionen/Seminare': 'admin/aktionen/Auditorverwaltung',
    'admin/aktionen/Auditorverwaltung': 'admin/aktionen/Auditorverwaltung',
    'admin/aktionen/Dozentenverwaltung': 'admin/aktionen/Dozentenverwaltung',
    'admin/benutzerkonto': 'admin/benutzerkonto',
    },
    icwAuditorUrlMapping: {
    'admin/aktionen': 'admin/aktionen',
    'admin/aktionen/Profil': 'admin/aktionen/Auditorreport',
    },
    editorNavigationData: [
  {
          id: 3,
          name: 'Aktionen',
          url: 'admin/aktionen'
        },
  {
          id: 4,
          name: 'Mein Profil',
          url: 'admin/aktionen/Profil'
        },
      {
        id:8,
        name: 'Dozentenverwaltung',
         url: 'admin/aktionen/Dozentenverwaltung',
      },
      {
        id: 5,
        name: 'Meine Dozenten',
         url: 'admin/aktionen/Dozenten',
      },
      {
        id: 6,
        name: 'Meine Seminare',
         url: 'admin/aktionen/Seminare',
      },
/*      {
        id: 6,
        name: 'Kontakt',
       url: 'admin/benutzerkonto'
      },*/
     {
       id: 7,
       name: 'Benutzerkonto',
      url: 'admin/benutzerkonto'
     },
    ]
  };


handleMenuClick = (e) => {
    if (e.key === '1') {
        this.props.history.push(`/admin/benutzerkonto`);
    } else if (e.key === '2') {
        this.handleLogout();
    }
};

  handleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  closeSideBar = () => {
    this.setState({ isOpen: false });
  }

  handleLogout = () => {
    removeToken();
//    setUser(undefined);
    this.props.history.push("/anmelden", { replace: true });
  };

  render() {
    const {  user, handleChange, navigationData, icwMapping,icwAuditor, icwAuditorUrlMapping, icwUrlMapping, editorNavigationData, experience, searchText, filterAdvancedHighlighted } = this.props;
    const { adminActive, isOpen } = this.state;
    const screenWidth = document.getElementById("root").clientWidth;
    const isMobile = screenWidth < 1194;

    const isICW = (user || {}).Rolle === "ICW-Admin";
    const isAnbieter = (user || {}).Rolle === "Anbieter";
    const isAuditor = (user || {}).Rolle === "Auditor";

    const isNotAnerkannt = !isICW && !isAuditor && !(((this.props.veranstaltungenData || []).find((f,i) => f.type === 'anbieter-profil') || {}).status === "Eingereicht - angenommen");

    const editorNavigationDataClean = editorNavigationData.filter(({ name }) => name !== 'Dozentenverwaltung' || (isICW));
    const editorNavigationDataToUse = isAuditor ? [editorNavigationDataClean[0], editorNavigationDataClean[3], {
           id: 7,
           name: 'Benutzerkonto',
          url: 'admin/benutzerkonto'
         }] : isNotAnerkannt ? [editorNavigationDataClean[0], {
                                            id: 7,
                                            name: 'Benutzerkonto',
                                           url: 'admin/benutzerkonto'
                                          }] : editorNavigationDataClean;
    const navFirstRow = navigationData;
    console.debug("editorNavigationDataToUse isNotAnerkannt", editorNavigationDataToUse, isNotAnerkannt);
    return (<>
    { isMobile && <Menu
        isOpen={isOpen}
        onOpen={this.handleIsOpen}
        onClose={this.handleIsOpen}
    right={true}>
                    {navigationData &&
                                        navigationData.map(({ id, name, url }, i) => (
                                            <Link
                                              onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                              to={{ pathname: `/${url}` }}
                                              key={"l--" + id}
                                              className="link"
                                            >
                                                <li className={"iwnav-mainnav-level1" + (((this.props.history.location.pathname.substr(1) || "").toLowerCase().indexOf(((isICW ? icwUrlMapping[url] : url) || "").toLowerCase()) > -1 ) ? " iwnav-current" : "")} key={id}>
                                                  <span className="label"> {name}</span>
                                                </li>
                                            </Link>
                                        ))}
                    {  adminActive && (editorNavigationDataToUse &&
                              <>
                              <Divider />

                              {editorNavigationDataToUse.map(({ id, name, url }, i) => (
                                 <Link
                                   onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                   to={{ pathname: `/${ isAuditor ? icwAuditorUrlMapping[url] || url : isICW ? icwUrlMapping[url] || url : url }` }}
                                   key={"l--" + id}
                                   className="link"
                                   
                                 >
                                   <li className={"iwnav-mainnav-level1" + (((this.props.history.location.pathname.substr(1) || "").toLowerCase().indexOf(((isICW ? icwUrlMapping[url] : url) || "").toLowerCase()) > -1 ) ? " iwnav-current" : "")} key={id}>
                                       <span className="label"> { isAuditor ? icwAuditor[name] || name : isICW ? icwMapping[name] || name : name}</span>
                                     </li>
                                 </Link>
                             ))}
                             </>
                             ) }
                             { !adminActive &&  <>
                             <Divider />
                             <Link
                                   onClick={() => { this.props.changeExperience('signin'); this.closeSideBar(); }}
                                   to={{ pathname: `/anmelden` }}
                                   key={"l--00" }
                                   className="link"
                                 >
                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf('sign') > -1) ? " iwnav-current" : "")} key={102}>
                                                            <span className="label"> Anmelden</span>
                                                         </li>

                                </Link></>}

                  </Menu> }
      <div className="header-wrap">
        <div style={{ height: isMobile || !this.props.user ? 92 : 134 }} className="header-wrapper extended navbar-fixed-top">
          <div className="header-left">
            <div className="nav">
              <div className="first-line">
               <div className="second-line-wrapper">
                <Link className="logo_a" to={{ pathname: `/` }}>
                  <div className="logoWrapper">
                    <img id="logo" src={Logo} alt="ICW Portal" />
                  </div>
                  <div className="logoLabels">
                    <span className="logoMain">ICW Portal</span><br/>
                    <span className="logoSubtitle">Anerkennungs- und Zertifizierungsstelle</span>
                  </div>
                </Link>
                <div id="topSearch" style={{ opacity: 0 }} className="search-line-wrapper">
                <div className="select-city">

                </div>
                <div className="select-experience">
                  <input
                    type="text"
                    placeholder="Suchen"
                    onChange={this.changeSearchText}
                    value={this.props.searchText}
                  />
                  <i className="fas fa-search" />
                </div>
               </div>
               </div>
              </div>
              { !!this.props.user && <div style={{ display: isMobile ? 'none' : 'inherit', marginBottom: isMobile ? '-48px' : 'inherit' }} className="second-line">

              <div className={"second-line adminNav" + (adminActive ? " adminNav--active" : "")}>
                                  <div className="second-line-wrapper">
                                       <div className="best-picked">
                                           <ul className="iwnav-channels">
                                         { (editorNavigationDataToUse &&
                                                 editorNavigationDataToUse.map(({ id, name, url }, i) => {
                                                    return name === 'Benutzerkonto' ? <Link style={{ paddingLeft: '24px' }}
                                                            onClick={() => { this.props.changeExperience(name); }}
                                                            to={{ pathname: `/${url}` }}
                                                            key={"l--" + id}
                                                            className="link"
                                                            ><div className="sign-in">
                                                            <SioAvatar handleMenuClick={this.handleMenuClick} />
                                                    </div></Link> : <Link
                                                      onClick={() => { this.props.changeExperience(name); }}
                                                      to={{ pathname: `/${isICW ? icwUrlMapping[url] : url}` }}
                                                      key={"l--" + id}
                                                      className="link"
                                                    >
                                                      <li className={"iwnav-mainnav-level1" + (((this.props.history.location.pathname.substr(1) || "").toLowerCase().indexOf(((isICW ? icwUrlMapping[url] : url) || "").toLowerCase()) > -1 ) ? " iwnav-current" : "")} key={id}>
                                                                              <span className="label"> { isAuditor ? icwAuditor[name] || name : isICW ? icwMapping[name] : name}</span>
                                                                            </li>
                                                    </Link>
                                                } )) }
                                           </ul>
                                       </div>
                                   </div>
                               </div>
               { screenWidth < 948 && <div className="second-line">
                 <div className="second-line-wrapper">
                  <div className="support support--second" style={{ marginLeft: screenWidth < 948 ? 0 : '12%'}}>
                  <ul className="iwnav-channels">
                                                           {editorNavigationDataToUse &&
                                                             editorNavigationDataToUse.map(({ id, name, url }, i) => (
                                                                 <Link
                                                                   onClick={() => { this.props.changeExperience(name); }}
                                                                   to={{ pathname: `/${url}` }}
                                                                   key={"l--" + id}
                                                                   className="link"
                                                                 >
                                                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                                                   d        <span className="label"> 2{name}</span>
                                                                                         </li>
                                                                 </Link>
                                                             ))}
                                                             </ul>
                                                     <div className={"i-button"} onClick={ () => this.props.history.push(`/admin/aktionen`) }>Inserieren</div>
                                    <p>
                                        <a
                                            onClick={ () => this.props.history.push(`/anmelden`) }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ textDecoration: 'none' }}
                                            >
                                              <div className="sign-in">
                                                   <SioAvatar handleMenuClick={this.handleMenuClick} />
                                              </div>
                                        </a>
                                      </p>
                                  </div>
                  </div>
                  </div>
                   }
              </div> }
            </div>

          </div>
        </div>
      </div>
      </>
    );
  }
}

const options = [
  { value: 'Veranstaltung', label: 'Veranstaltung', route: '/veranstaltungen' },
  { value: 'Referent', label: 'Referent', route: '/referenten'  },
  { value: 'Hersteller', label: 'Hersteller', route: '/hersteller'  },
  { value: 'Produkt', label: 'Produkte', route: '/hersteller'  },
  { value: 'Versorgungspartner', label: 'Versorgungspartner', route: '/versorgungspartner'  },
];

const optionsHome = [
  { label: 'Ich suche nach Veranstaltungen', value: 'Veranstaltung', route: '/veranstaltungen' },
  { label: 'Ich suche nach Referenten', value: 'Referent', route: '/referenten'  },
  { label: 'Ich suche nach Herstellern', value: 'Hersteller', route: '/hersteller'  },
  { label: 'Ich suche nach Produkte', value: 'Produkte', route: '/hersteller'  },
  { label: 'Ich suche nach Versorgungspartner', value: 'Versorgungspartner', route: '/versorgungspartner'  },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'red' : '#727272',
    padding: 10,
    cursor: 'pointer',
    background: state.isSelected ? 'white' : 'white',
    fontSize: '13px',
    textAlign: 'left',
    width: 120
  }),
  control: () => ({
    width: 150,
    display: 'flex',
    fontSize: '14px',
    marginTop: '10px',
    paddingLeft: '5px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

const smallSearchbar = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'red' : '#727272',
    padding: 10,
    cursor: 'pointer',
    background: state.isSelected ? 'white' : 'white',
    fontSize: '15px',
    textAlign: 'left',
    paddingLeft: '20px',
    width: 150
  }),
  control: () => ({
    width: 160,
    display: 'flex',
    fontSize: '14px',
    marginLeft: '15px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

export default withRouter(Header);
import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Input, Button, Select, Checkbox, DatePicker, Steps, Divider, Typography, message, Upload, Card,Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined, UserOutlined, FormOutlined, SolutionOutlined, TeamOutlined, AppstoreOutlined, SettingOutlined, UsergroupAddOutlined, CheckCircleOutlined, FileTextOutlined, ContactsOutlined, AuditOutlined, PaperClipOutlined, InfoCircleOutlined, DollarOutlined, SaveOutlined, TrophyOutlined } from '@ant-design/icons';

export const Pruefungsaudit = [
                              {
                                "title": "1. Allgemeine Anforderungen an den Veranstalter (Prüffeld 1)",
                                "icon": <FormOutlined />,
                                "forms": [
                                  {
                                    "label": "Auditteilnehmer des Anbieters",
                                    "type": "string",
                                    "key": "Auditteilnehmer des Anbieters"
                                  },
                                  {
                                    "label": "Auditor/en",
                                    "type": "string",
                                    "key": "Auditor/en"
                                  },
                                  {
                                    "label": "Auditart",
                                    "type": "select",
                                    "key": "Auditart",
                                    "options": ["Regelaudit", "Überwachungsaudit", "Berücksichtigung von Auflagen vorangegangener Audits"]
                                  },
                                  {
                                    "label": "Audittyp",
                                    "type": "select",
                                    "key": "Audittyp",
                                    "options": ["Aktenaudit", "Prüfungsaudit", "Seminaraudit"]
                                  },
                                  {
                                    "label": "4.1.1 Die Zulassungsvoraussetzung wurde geprüft und entspricht in der Stichprobe der Zielgruppe",
                                    "type": "select",
                                    "key": "4.1.1",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.1.1"
                                  },
                                  {
                                    "label": "4.1.2 Die erforderlichen Präsenszeiten der Prüflinge wurde zuvor überprüft und bestätigt mind.  80% Anwesenheit.",
                                    "type": "select",
                                    "key": "4.1.2",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.1.2"
                                  },
                                  {
                                    "condition": "praesenz",
                                    "label": "4.1.3 Die Prüfungsklausur wird von den im Prüfungsgremium benannten Prüfern abgenommen oder eine abweichende Prüfungsaufsicht wurde lt. Einweisung Prüfungsaufsicht instruiert und bei der Zert-stelle gemeldet.",
                                    "type": "select",
                                    "key": "4.1.3",
                                    "options": ["voll", "teils", "nicht"]
                                  },
                                  {
                                      "condition": "praesenz",
                                    "label": "Anmerkungen:",
                                    "type": "string",
                                    "key": "Anmerkungen 4.1.3"
                                  }
                                ]
                              },
                              {
                                "title": "2. Prüfungsdurchführung (Prüffeld 4.2.)",
                                "icon": <FormOutlined />,
                                "forms": [
                                    {
                                      "condition": "praesenz",
                                      "label": "4.2.1 Die Prüflinge erhalten vom Prüfer eine angemessene Prüfungseinweisung (siehe Checkliste Prüfungseinweisung).",
                                      "type": "select",
                                      "key": "4.2.1",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.2.1"
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "4.2.2 Der Prüfer stellt die Vorgaben zur Prüfungsabnahme sicher und kommt seiner Aufsichtspflicht während der gesamten Prüfungsklausur nach.",
                                      "type": "select",
                                      "key": "4.2.2",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.2.2"
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "4.2.3 Die Prüfung findet an einem bei der Zertifizierungsstelle gemeldeten Ort und Zeit statt.",
                                      "type": "select",
                                      "key": "4.2.3",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.2.3"
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "4.2.4 Die Prüfung findet in angemessenen Räumlichkeiten statt. (Klausur Einzeltische bzw. A und B- Klausur bzw. für Colloquium geeigneter Seminarraum mit Medien.",
                                      "type": "select",
                                      "key": "4.2.4",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.2.4"
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "4.2.5 Die Prüfungsatmosphäre ist der Leistungsschöpfung dienlich (Ruhe, Licht, Temperatur).",
                                      "type": "select",
                                      "key": "4.2.5",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.2.5"
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "4.2.6 Nur für Colloquium Das Colloquium wird von den im Prüfungsgremium benannten Prüfer abgenommen.",
                                      "type": "select",
                                      "key": "4.2.6",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.2.6"
                                    },
                                    {
                                      "condition": "praesenz",
                                      "label": "4.2.7 Nur für Colloquium Die beiden Prüfer führen ein sinnvolles Prüfungsgespräch, welches der Leistungseinschätzung dienlich ist.",
                                      "type": "select",
                                      "key": "4.2.7",
                                      "options": ["voll", "teils", "nicht"]
                                    },

                                    {
                                      "condition": "praesenz",
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.2.7"
                                    },
                                ]
                              },
                              {
                                "title": "3. Prüfungsbewertung (Prüffeld 4.3.)",
                                "icon": <FormOutlined />,
                                "forms": [
                                    {
                                      "label": "4.3.1 Die Bewertung erfolgt durch die von der Zertifizierungsstelle bestätigten Prüfungsbeauftragten/Prüfungsgremium.",
                                      "type": "select",
                                      "key": "4.3.1",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.1"
                                    },
                                    {
                                      "label": "4.3.2 Die Bewertung der Leistungsnachweise erfolgt nachvollziehbar.",
                                      "type": "select",
                                      "key": "4.3.2",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.2"
                                    },
                                    {
                                      "label": "4.3.3 Die Prüfungsunterlagen werden durch den Prüfungsbeauftragten gegengezeichnet.",
                                      "type": "select",
                                      "key": "4.3.3",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.3"
                                    },



                                    {
                                      "label": "4.3.4 Die Dokumentation der Prüfung entspricht der in der Prüfungsniederschrift. Kann ggf. anhand voriger Prüfungen bewertet werden.",
                                      "type": "select",
                                      "key": "4.3.4",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.4"
                                    },
                                    {
                                      "label": "4.3.5 Die Prüfungen werden innerhalb der vorgegebenen Frist von 6 Wochen mit der Prüfungsniederschrift beim TÜV eingereicht. Kann ggf. anhand voriger Prüfungen bewertet werden.",
                                      "type": "select",
                                      "key": "4.3.5",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.5"
                                    },
                                    {
                                      "label": "4.3.6 Die Bewertung der Prüfungen erfolgt fachlich korrekt.",
                                      "type": "select",
                                      "key": "4.36",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.6"
                                    },
                                    {
                                      "label": "4.3.7 Die Bewertung der Prüfungen erfolgt formal korrekt und wird nachvollziehbar anhand der Vorgaben und Bewertungskriterien bewertet (Bewertungsmatrix; Lösungsschlüssel, Zielvorgaben HA,…) bewertet.",
                                      "type": "select",
                                      "key": "4.3.7",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.7"
                                    },
                                    {
                                      "label": "4.3.8 Bei Nicht -Bestehen einer Hausarbeit erfolgt eine aussagekräftige Rückmeldung mittels der ausgefüllten Bewertungsmatrix an den Teilnehmer, auf deren Basis er eine Überarbeitung vornehmen kann.",
                                      "type": "select",
                                      "key": "4.3.8",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.8"
                                    },
                                    {
                                      "label": "4.3.9 Die Prüfungsunterlagen werden systematisch archiviert.",
                                      "type": "select",
                                      "key": "4.3.9",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.9"
                                    },
                                    {
                                      "label": "4.3.10 Ggf. anberaumte Nachschreibetermine liegen innerhalb der Frist lt. Prüfungsvorgaben.",
                                      "type": "select",
                                      "key": "4.3.10",
                                      "options": ["voll", "teils", "nicht"]
                                    },
                                    {
                                      "label": "Anmerkungen:",
                                      "type": "string",
                                      "key": "Anmerkungen 4.3.10"
                                    },
                                ]
                              },
                                        {
                                           printHide: true,
                                            title: '4. Bestätigung und Einreichen',
                                            icon: <TrophyOutlined />,
                                            htmlbox: `Um den Antrag fertigzustellen, drucken Sie bitte dieses Formular aus und laden es unterschrieben wieder hoch. Danach drücken Sie bitte auf "Einreichen" um den Antrag abzuschicken.`,
                                              forms: [
                                              { label: 'herunterladen', type: 'button' },
                                            { label: 'Unterschriebener Antrag:', type: 'upload' },
                                            { required: true, key: 'bestaetigung' , label: 'Die Auditergebnisse wurden mit dem Verantwortlichen des Bildungsanbieters besprochen und zur Kenntnis genommen. Notwendige Schlussfolgerungen und Korrekturmaßnahmen werden durch die Anerkennungs- und Zertifizierungsstelle von TÜV und ICW e. V. festgelegt und dem Anbieter in Schriftform zur Kenntnis gebracht.', type: 'checkbox' }],
                                          },// 2 unterschriften // Ort Datum
                            ];
